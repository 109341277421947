export const environment = {
	production: true,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	
	loginUrl: 'https://endpoints-backend.api.preprod.assistive.site/retailitynew',
  	baseUrl:'',
	imageUrl: 'https://static.preprod.assistive.site/',
	name:'assistive-preprod',
	foamicoBandhanAccessToken: 'EAAWtJ0zNZB6cBO2wHvTtmXLqZA8bj2Ygm40SraETkEa5BQ40uszRZC1d1EUVGscW2kQ5Y3FQODh8S1EoO5HoAvFZAnIeW3BcgonBZAjuu2oZCIjycO0M97kvtbuPMrHZA6Xhal0FDBnZA0pw6x2LTZBzzROQfeoI3BY5pjDsnZBQkG0CgZBrFYPZAnHdHidBxkCjaKj0UgZDZD'
};